//Polyfills for IE11.
import 'core-js/features/array'
import 'core-js/features/object'
import 'core-js/features/string/includes'
import 'core-js/features/string/starts-with'
import 'whatwg-fetch'
import 'core-js/features/math/log10'
import 'core-js/features/symbol'
import 'core-js/features/promise'
import 'classlist-polyfill'
import 'url-polyfill'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'regenerator-runtime/runtime'


if (!String.prototype.format) {
	String.prototype.format = function() {
		var args = arguments
		return this.replace(/{(\d+)}/g, function(match, number) {
			return typeof args[number] != 'undefined' ? args[number] : match
		})
	}
}

//Polyfill for closest
if (!Element.prototype.matches)
	Element.prototype.matches = Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector

if (!Element.prototype.closest)
	Element.prototype.closest = function (s) {
		var el = this
		if (!document.documentElement.contains(el)) return null
		do {
			if (el.matches(s)) return el
			el = el.parentElement || el.parentNode
		} while (el !== null && el.nodeType === 1)
		return null
	}

//Polyfill for remove
if (!('remove' in Element.prototype)) {
	Element.prototype.remove = function () {
		if (this.parentNode) {
			this.parentNode.removeChild(this)
		}
	}
}